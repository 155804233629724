"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transfer = void 0;
exports.Transfer = {
    'zh-CN': {
        title: '穿梭框',
        settings: {
            'x-component-props': {
                oneWay: '单向展示',
                operations: { title: '操作文案集合', tooltip: '格式：string[]' },
                titles: { title: '标题集合', tooltip: '格式：string[]' },
                showSearchAll: '支持全选',
                filterOption: '选项筛选器',
            },
        },
    },
    'en-US': {
        title: 'Transfer',
        settings: {
            'x-component-props': {
                oneWay: 'One Way',
                operations: { title: 'Operations', tooltip: 'Format：string[]' },
                titles: { title: 'Titles', tooltip: 'Format：string[]' },
                showSearchAll: 'Show Search All',
                filterOption: 'Filter Option',
            },
        },
    },
    'ko-KR': {
        title: '전송',
        settings: {
            'x-component-props': {
                oneWay: '하나의 방법',
                operations: { title: '작업', tooltip: '형식：string[]' },
                titles: { title: '제목', tooltip: '형식：string[]' },
                showSearchAll: '모든 검색 결과 보여주기',
                filterOption: '필터 옵션',
            },
        },
    },
};
