"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
exports.Switch = {
    'zh-CN': {
        title: '开关',
    },
    'en-US': {
        title: 'Switch',
    },
    'ko-KR': {
        title: '스위치',
    },
};
