"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroup = void 0;
exports.RadioGroup = {
    'zh-CN': {
        title: '单选框组',
        settings: {
            'x-component-props': {
                buttonStyle: { title: '按钮风格', dataSource: ['空心', '实心'] },
                optionType: { title: '选项类型', dataSource: ['默认', '按钮'] },
            },
        },
    },
    'en-US': {
        title: 'Radio',
        settings: {
            'x-component-props': {
                buttonStyle: { title: 'Button style', dataSource: ['Hollow', 'Solid'] },
                optionType: { title: 'Option type', dataSource: ['Default', 'Button'] },
            },
        },
    },
    'ko-KR': {
        title: '라디오',
        settings: {
            'x-component-props': {
                buttonStyle: { title: '버튼 스타일', dataSource: ['Hollow', 'Solid'] },
                optionType: { title: '옵션 타입', dataSource: ['기본', '버튼'] },
            },
        },
    },
};
