"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectLocale = void 0;
exports.ObjectLocale = {
    'zh-CN': {
        title: '对象容器',
    },
    'en-US': {
        title: 'Object',
    },
    'ko-KR': {
        title: '오브젝트',
    },
};
