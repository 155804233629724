"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFormDataByUser = exports.saveFormDataByUser = exports.loadFormDataByUser = exports.updateFormDataByid = exports.loadFormDataAdminByid = exports.loadFormDataByid = exports.loadSchemaData = exports.loadInitialSchema = exports.saveSchema = void 0;
var formily_transformer_1 = require("@designable/formily-transformer");
var antd_1 = require("antd");
var request_1 = require("../utils/request");
var storage = window.localStorage;
var saveSchema = function (designer) { return __awaiter(void 0, void 0, void 0, function () {
    var dynamicformid, result, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                dynamicformid = storage.getItem('dynamicformid');
                if (!(dynamicformid && dynamicformid !== 'undefined')) return [3 /*break*/, 2];
                console.log("update", designer.getCurrentTree(), (0, formily_transformer_1.transformToSchema)(designer.getCurrentTree()));
                return [4 /*yield*/, (0, request_1.request)({
                        method: 'POST',
                        url: '/jeecg/dynamicForm/update',
                        params: {
                            dynamicformid: dynamicformid,
                            designcode: (0, formily_transformer_1.transformToSchema)(designer.getCurrentTree())
                        },
                    })];
            case 1:
                result = _a.sent();
                console.log("result", result);
                // 更新
                antd_1.message.success('更新成功');
                return [3 /*break*/, 3];
            case 2:
                // 新建
                antd_1.message.success('保存成功');
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                error_1 = _a.sent();
                console.log("saveSchema error", error_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
exports.saveSchema = saveSchema;
var loadInitialSchema = function (designer) { return __awaiter(void 0, void 0, void 0, function () {
    var dynamicformid, result, jsonData, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                dynamicformid = storage.getItem('dynamicformid');
                if (!(dynamicformid && dynamicformid !== 'undefined')) return [3 /*break*/, 2];
                console.log('有数据');
                return [4 /*yield*/, (0, request_1.request)({
                        method: 'GET',
                        url: '/jeecg/dynamicForm/form',
                        params: {
                            formId: dynamicformid
                        },
                    })];
            case 1:
                result = _a.sent();
                jsonData = result.data.data.designcodeJson || {};
                // console.log("result", result, jsonData)
                designer.setCurrentTree((0, formily_transformer_1.transformToTreeNode)(jsonData));
                _a.label = 2;
            case 2: return [3 /*break*/, 4];
            case 3:
                error_2 = _a.sent();
                console.log(error_2);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
exports.loadInitialSchema = loadInitialSchema;
// 查看表单数据
var loadSchemaData = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var dynamicformid, result, jsonData, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            dynamicformid = storage.getItem('dynamicformid');
                            console.log('dynamicformid 表单id', dynamicformid);
                            if (!(dynamicformid && dynamicformid !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'GET',
                                    url: '/newpatient-service/dynamicForm/form',
                                    params: {
                                        formId: dynamicformid
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            jsonData = result.data.data.designcodeJson;
                            resolve(jsonData);
                            _a.label = 2;
                        case 2: return [3 /*break*/, 4];
                        case 3:
                            error_3 = _a.sent();
                            console.log(error_3);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.loadSchemaData = loadSchemaData;
// 查看表单填写数据
var loadFormDataByid = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var formDataId, result, error_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            formDataId = storage.getItem('formDataId');
                            console.log('dynamicformid 表单id', formDataId);
                            if (!(formDataId && formDataId !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'GET',
                                    url: '/newpatient-service/dynamicForm/formDataRecord',
                                    params: {
                                        id: formDataId
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            return [3 /*break*/, 3];
                        case 2:
                            resolve({});
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_4 = _a.sent();
                            console.log(error_4);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.loadFormDataByid = loadFormDataByid;
// admin 查看表单填写数据
var loadFormDataAdminByid = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var dynamicformid, identityId, userFormDataId, result, error_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            dynamicformid = storage.getItem('dynamicformid');
                            identityId = storage.getItem('identityId');
                            userFormDataId = storage.getItem('userFormDataId');
                            console.log('dynamicformid 表单id', userFormDataId);
                            if (!(userFormDataId && userFormDataId !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'GET',
                                    url: '/jeecg/dynamicForm/formDataRecordByDataId',
                                    params: {
                                        // identityId,
                                        // formId: dynamicformid,
                                        formDataId: userFormDataId,
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            return [3 /*break*/, 3];
                        case 2:
                            resolve({});
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_5 = _a.sent();
                            console.log(error_5);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.loadFormDataAdminByid = loadFormDataAdminByid;
// 更新表单数据
var updateFormDataByid = function (value) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var formDataId, result, error_6;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            formDataId = storage.getItem('formDataId');
                            console.log('dynamicformid 表单id', formDataId);
                            if (!(formDataId && formDataId !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'POST',
                                    url: '/jeecg/dynamicForm/updateFormDataById',
                                    params: {
                                        _id: formDataId,
                                        data: __assign({}, value)
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            _a.label = 2;
                        case 2: return [3 /*break*/, 4];
                        case 3:
                            error_6 = _a.sent();
                            console.log(error_6);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.updateFormDataByid = updateFormDataByid;
// 患者查看表单填写数据
var loadFormDataByUser = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var identityId, dynamicformid, userFormDataId, result, error_7;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            identityId = storage.getItem('identityId');
                            dynamicformid = storage.getItem('dynamicformid');
                            userFormDataId = storage.getItem('userFormDataId');
                            if (!(userFormDataId && userFormDataId !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'GET',
                                    url: '/newpatient-service/dynamicForm/formDataRecordByDataId',
                                    params: {
                                        // formId: dynamicformid,
                                        // identityId: identityId,
                                        formDataId: userFormDataId,
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            return [3 /*break*/, 3];
                        case 2:
                            resolve({});
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_7 = _a.sent();
                            console.log(error_7);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.loadFormDataByUser = loadFormDataByUser;
// 更新表单数据
var saveFormDataByUser = function (value) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var dynamicformid, identityId, senderContactId, userFormDataId, result, error_8;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            dynamicformid = storage.getItem('dynamicformid');
                            identityId = storage.getItem('identityId');
                            senderContactId = storage.getItem('senderContactId');
                            userFormDataId = storage.getItem('userFormDataId');
                            if (!(dynamicformid && dynamicformid !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'POST',
                                    url: '/newpatient-service/dynamicForm/saveFormDataByObject',
                                    params: {
                                        dataObj: __assign({}, value),
                                        identityId: identityId,
                                        senderContactId: senderContactId,
                                        formId: dynamicformid,
                                        formDataId: userFormDataId,
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            _a.label = 2;
                        case 2: return [3 /*break*/, 4];
                        case 3:
                            error_8 = _a.sent();
                            console.log(error_8);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.saveFormDataByUser = saveFormDataByUser;
// 更新表单数据
var updateFormDataByUser = function (value) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                var formDataId, result, error_9;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            formDataId = storage.getItem('formDataId');
                            console.log('dynamicformid 表单id', formDataId);
                            if (!(formDataId && formDataId !== 'undefined')) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, request_1.request)({
                                    method: 'POST',
                                    url: '/jeecg/dynamicForm/updateFormDataById',
                                    params: {
                                        _id: formDataId,
                                        data: __assign({}, value)
                                    },
                                })];
                        case 1:
                            result = _a.sent();
                            resolve(result.data.data);
                            _a.label = 2;
                        case 2: return [3 /*break*/, 4];
                        case 3:
                            error_9 = _a.sent();
                            console.log(error_9);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
exports.updateFormDataByUser = updateFormDataByUser;
