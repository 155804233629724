"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rate = void 0;
exports.Rate = {
    'zh-CN': {
        title: '评分器',
        settings: {
            'x-component-props': {
                allowHalf: '允许半选',
                tooltips: { title: '提示信息', tooltip: '格式：string[]' },
                count: '总数',
            },
        },
    },
    'en-US': {
        title: 'Rate',
        settings: {
            'x-component-props': {
                allowHalf: 'Allow Half',
                tooltips: { title: 'Tooltips', tooltip: 'Format：string[]' },
                count: 'Count',
            },
        },
    },
    'ko-KR': {
        title: '비율',
        settings: {
            'x-component-props': {
                allowHalf: '절반 허용',
                tooltips: { title: '툴팁', tooltip: '형식：string[]' },
                count: '개수',
            },
        },
    },
};
