"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slider = void 0;
exports.Slider = {
    'zh-CN': {
        title: '滑动条',
        settings: {
            'x-component-props': {
                dots: '刻度固定',
                range: '双滑块',
                reverse: '反向坐标系',
                vertical: '垂直布局',
                tooltipPlacement: {
                    title: '提示位置',
                    tooltip: '设置 提示 展示位置。参考 Tooltip',
                },
                tooltipVisible: {
                    title: '提示显示',
                    tooltip: '开启时，提示 将会始终显示；否则始终不显示，哪怕在拖拽及移入时',
                },
                max: '最大值',
                min: '最小值',
                step: '步长',
                marks: '刻度标签',
            },
        },
    },
    'en-US': {
        title: 'Slider',
        settings: {
            'x-component-props': {
                dots: 'Fixed Scale',
                range: 'Double Slider',
                reverse: 'Reverse Coordinate System',
                vertical: 'Vertical',
                tooltipPlacement: {
                    title: 'Tooltip Placement',
                    tooltip: 'Set up prompt placement. Reference Tooltip',
                },
                tooltipVisible: {
                    title: 'Tooltip Visible',
                    tooltip: 'When turned on, the prompt will always be displayed; otherwise, it will always not be displayed, even when dragging and moving in',
                },
                max: 'Max',
                min: 'Min',
                step: 'Step',
                marks: 'Marks',
            },
        },
    },
    'ko-KR': {
        title: '슬라이더',
        settings: {
            'x-component-props': {
                dots: '고정된 크기',
                range: '더블 슬라이더',
                reverse: '뒤집어진 좌표 시스템',
                vertical: '수직',
                tooltipPlacement: {
                    title: '툴팁 배치',
                    tooltip: '툴팁이 나오는 위치를 설정',
                },
                tooltipVisible: {
                    title: '툴팁 보기',
                    tooltip: '이 옵션을 켜면 툴팁이 항상 표시됩니다. 그렇지 않으면 끌어서 이동할 때도 툴팁이 항상 표시되지 않습니다',
                },
                max: '최댓값',
                min: '최솟값',
                step: '스탭',
                marks: '마크',
            },
        },
    },
};
