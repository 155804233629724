"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Space = void 0;
exports.Space = {
    'zh-CN': {
        title: '弹性间距',
        settings: {
            'x-component-props': {
                direction: { title: '方向', dataSource: ['垂直', '水平'] },
                split: '分割内容',
                wrap: '自动换行',
                align: {
                    title: '对齐',
                    dataSource: ['头部', '尾部', '居中', '基准线'],
                },
            },
        },
    },
    'en-US': {
        title: 'Space',
        settings: {
            'x-component-props': {
                direction: {
                    title: 'Direction',
                    dataSource: ['Vertical', 'Horizontal'],
                },
                split: 'Split',
                wrap: 'Word Wrap',
                align: {
                    title: 'Align',
                    dataSource: ['Start', 'End', 'Center', 'Baseline'],
                },
            },
        },
    },
    'ko-KR': {
        title: '간격',
        settings: {
            'x-component-props': {
                direction: {
                    title: '방향',
                    dataSource: ['수직', '수평'],
                },
                split: '분할',
                wrap: '자동 줄 바꿈',
                align: {
                    title: '정렬',
                    dataSource: ['시작', '끝', '가운데', '기준선'],
                },
            },
        },
    },
};
