"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
exports.Component = {
    'zh-CN': {
        settings: {
            style: {
                width: '宽度',
                height: '高度',
                display: '展示',
                background: '背景',
                boxShadow: '阴影',
                font: '字体',
                margin: '外边距',
                padding: '内边距',
                borderRadius: '圆角',
                border: '边框',
                opacity: '透明度',
            },
        },
    },
    'en-US': {
        settings: {
            style: {
                width: 'Width',
                height: 'Height',
                display: 'Display',
                background: 'Background',
                boxShadow: 'Box Shadow',
                font: 'Font',
                margin: 'Margin',
                padding: 'Padding',
                borderRadius: 'Radius',
                border: 'Border',
                opacity: 'Opacity',
            },
        },
    },
    'ko-KR': {
        settings: {
            style: {
                width: '너비',
                height: '높이',
                display: '디스플레이',
                background: '배경',
                boxShadow: '쉐도우 박스',
                font: '폰트',
                margin: '마진',
                padding: '패딩',
                borderRadius: '테두리 굴곡',
                border: '테두리',
                opacity: '투명도',
            },
        },
    },
};
