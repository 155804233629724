"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_2 = require("@designable/react");
var react_settings_form_1 = require("@designable/react-settings-form");
var core_1 = require("@designable/core");
var widgets_1 = require("./widgets");
var service_1 = require("./service");
var src_1 = require("../src");
(0, react_settings_form_1.setNpmCDNRegistry)('//unpkg.com');
core_1.GlobalRegistry.registerDesignerLocales({
    'zh-CN': {
        sources: {
            Inputs: '输入控件',
            Layouts: '布局组件',
            Arrays: '自增组件',
            Displays: '展示组件',
        },
    },
    'en-US': {
        sources: {
            Inputs: 'Inputs',
            Layouts: 'Layouts',
            Arrays: 'Arrays',
            Displays: 'Displays',
        },
    },
    'ko-KR': {
        sources: {
            Inputs: '입력',
            Layouts: '레이아웃',
            Arrays: '배열',
            Displays: '디스플레이',
        },
    },
});
var Manage = function () {
    var engine = (0, react_1.useMemo)(function () {
        return (0, core_1.createDesigner)({
            shortcuts: [
                new core_1.Shortcut({
                    codes: [
                        [core_1.KeyCode.Meta, core_1.KeyCode.S],
                        [core_1.KeyCode.Control, core_1.KeyCode.S],
                    ],
                    handler: function (ctx) {
                        (0, service_1.saveSchema)(ctx.engine);
                    },
                }),
            ],
            rootComponentName: 'Form',
        });
    }, []);
    return (react_1.default.createElement(react_2.Designer, { engine: engine },
        react_1.default.createElement(react_2.StudioPanel, { logo: react_1.default.createElement(widgets_1.LogoWidget, null), actions: react_1.default.createElement(widgets_1.ActionsWidget, null) },
            react_1.default.createElement(react_2.CompositePanel, null,
                react_1.default.createElement(react_2.CompositePanel.Item, { title: "panels.Component", icon: "Component" },
                    react_1.default.createElement(react_2.ResourceWidget, { title: "sources.Inputs", sources: [
                            src_1.Input,
                            src_1.Password,
                            src_1.NumberPicker,
                            src_1.Rate,
                            src_1.Slider,
                            src_1.Select,
                            // TreeSelect,
                            // Cascader,
                            // Transfer,
                            src_1.Checkbox,
                            src_1.Radio,
                            src_1.DatePicker,
                            // TimePicker,
                            // Upload,
                            src_1.Switch,
                            // ObjectContainer,
                        ] }),
                    react_1.default.createElement(react_2.ResourceWidget, { title: "sources.Displays", sources: [src_1.Text] })),
                react_1.default.createElement(react_2.CompositePanel.Item, { title: "panels.OutlinedTree", icon: "Outline" },
                    react_1.default.createElement(react_2.OutlineTreeWidget, null)),
                react_1.default.createElement(react_2.CompositePanel.Item, { title: "panels.History", icon: "History" },
                    react_1.default.createElement(react_2.HistoryWidget, null))),
            react_1.default.createElement(react_2.Workspace, { id: "form" },
                react_1.default.createElement(react_2.WorkspacePanel, null,
                    react_1.default.createElement(react_2.ToolbarPanel, null,
                        react_1.default.createElement(react_2.DesignerToolsWidget, null),
                        react_1.default.createElement(react_2.ViewToolsWidget, { use: ['DESIGNABLE', 'JSONTREE', 'MARKUP', 'PREVIEW'] })),
                    react_1.default.createElement(react_2.ViewportPanel, { style: { height: '100%' } },
                        react_1.default.createElement(react_2.ViewPanel, { type: "DESIGNABLE" }, function () { return (react_1.default.createElement(react_2.ComponentTreeWidget, { components: {
                                Form: src_1.Form,
                                Field: src_1.Field,
                                Input: src_1.Input,
                                Select: src_1.Select,
                                TreeSelect: src_1.TreeSelect,
                                Cascader: src_1.Cascader,
                                Radio: src_1.Radio,
                                Checkbox: src_1.Checkbox,
                                Slider: src_1.Slider,
                                Rate: src_1.Rate,
                                NumberPicker: src_1.NumberPicker,
                                // Transfer,
                                Password: src_1.Password,
                                DatePicker: src_1.DatePicker,
                                TimePicker: src_1.TimePicker,
                                // Upload,
                                Switch: src_1.Switch,
                                Text: src_1.Text,
                                Card: src_1.Card,
                                ArrayCards: src_1.ArrayCards,
                                ArrayTable: src_1.ArrayTable,
                                Space: src_1.Space,
                                FormTab: src_1.FormTab,
                                FormCollapse: src_1.FormCollapse,
                                FormGrid: src_1.FormGrid,
                                FormLayout: src_1.FormLayout,
                                ObjectContainer: src_1.ObjectContainer,
                            } })); }),
                        react_1.default.createElement(react_2.ViewPanel, { type: "JSONTREE", scrollable: false }, function (tree, onChange) { return (react_1.default.createElement(widgets_1.SchemaEditorWidget, { tree: tree, onChange: onChange })); }),
                        react_1.default.createElement(react_2.ViewPanel, { type: "MARKUP", scrollable: false }, function (tree) { return react_1.default.createElement(widgets_1.MarkupSchemaWidget, { tree: tree }); }),
                        react_1.default.createElement(react_2.ViewPanel, { type: "PREVIEW" }, function (tree) { return react_1.default.createElement(widgets_1.PreviewWidget, { tree: tree }); })))),
            react_1.default.createElement(react_2.SettingsPanel, { title: "panels.PropertySettings" },
                react_1.default.createElement(react_settings_form_1.SettingsForm, { uploadAction: "https://www.mocky.io/v2/5cc8019d300000980a055e76" })))));
};
exports.default = Manage;
