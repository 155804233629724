"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var core_1 = require("@formily/core");
var react_2 = require("@formily/react");
var antd_1 = require("@formily/antd");
var antd_2 = require("antd");
var service_1 = require("./service");
var storage = window.localStorage;
var Text = function (_a) {
    var value = _a.value, mode = _a.mode, content = _a.content, props = __rest(_a, ["value", "mode", "content"]);
    var tagName = mode === 'normal' || !mode ? 'div' : mode;
    return react_1.default.createElement(tagName, props, value || content);
};
var SchemaField = (0, react_2.createSchemaField)({
    components: {
        Space: antd_1.Space,
        FormGrid: antd_1.FormGrid,
        FormLayout: antd_1.FormLayout,
        FormTab: antd_1.FormTab,
        FormCollapse: antd_1.FormCollapse,
        ArrayTable: antd_1.ArrayTable,
        ArrayCards: antd_1.ArrayCards,
        FormItem: antd_1.FormItem,
        DatePicker: antd_1.DatePicker,
        Checkbox: antd_1.Checkbox,
        Cascader: antd_1.Cascader,
        Editable: antd_1.Editable,
        Input: antd_1.Input,
        Text: Text,
        NumberPicker: antd_1.NumberPicker,
        Switch: antd_1.Switch,
        Password: antd_1.Password,
        PreviewText: antd_1.PreviewText,
        Radio: antd_1.Radio,
        Reset: antd_1.Reset,
        Select: antd_1.Select,
        Submit: antd_1.Submit,
        TimePicker: antd_1.TimePicker,
        Transfer: antd_1.Transfer,
        TreeSelect: antd_1.TreeSelect,
        Upload: antd_1.Upload,
        Card: antd_2.Card,
        Slider: antd_2.Slider,
        Rate: antd_2.Rate,
    },
});
var Preview = function (props) {
    var readPretty = storage.getItem('readPretty');
    console.log("readPretty", readPretty, readPretty === 'undefined');
    var terminal = storage.getItem('terminal');
    var isView = readPretty && readPretty !== 'undefined' && readPretty !== 'false' ? true : false;
    var form = (0, react_1.useMemo)(function () { return (0, core_1.createForm)({
        readPretty: isView,
    }); }, []);
    var _a = __read((0, react_1.useState)({}), 2), formProps = _a[0], setFormProps = _a[1];
    var _b = __read((0, react_1.useState)({}), 2), schema = _b[0], setSchema = _b[1];
    var _c = __read((0, react_1.useState)(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read((0, react_1.useState)(isView), 2), viewStatus = _d[0], setViewStatus = _d[1];
    var _e = __read((0, react_1.useState)({}), 2), resultData = _e[0], setResultData = _e[1];
    console.log("form", form);
    (0, react_1.useEffect)(function () {
        loadData();
        loadFormData();
        // setTimeout(() => {
        //   console.log("修改 readPretty")
        //   // form.setInitialValues({
        //   //   mrc985xr5cz: 'Aston Martin',
        //   // })
        // }, 1000)
    }, []);
    var handleDefaultText = function () {
        setTimeout(function () {
            var domList = document.querySelectorAll(".ant-space-item");
            var domOther = document.querySelectorAll(".ant-form-text");
            var resultList = __spreadArray(__spreadArray([], __read(domList), false), __read(domOther), false);
            // console.log("domList", resultList)
            resultList.forEach(function (item) {
                if (item.innerHTML === 'N/A') {
                    item.innerHTML = "";
                }
            });
        }, 300);
    };
    // 表单结构数据
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, service_1.loadSchemaData)()];
                case 1:
                    result = _a.sent();
                    console.log("表单结构数据 result", result);
                    setFormProps(result.form);
                    setSchema(result.schema);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // 表单数据
    var loadFormData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    result = {};
                    if (!(terminal === "patient")) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, service_1.loadFormDataByUser)()];
                case 1:
                    // 患者只能填写一次
                    result = _a.sent();
                    if (result._id) {
                        form.readPretty = true;
                        // isView = true
                        setViewStatus(true);
                        form.setInitialValues(result);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    if (!(terminal === "admin")) return [3 /*break*/, 4];
                    return [4 /*yield*/, (0, service_1.loadFormDataAdminByid)()];
                case 3:
                    result = _a.sent();
                    form.setInitialValues(result);
                    _a.label = 4;
                case 4:
                    setResultData(result);
                    console.log("表单数据 result", result, form);
                    handleDefaultText();
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    console.log("loadFormData error", error_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (val) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("handleSubmit", val);
                    if (loading)
                        return [2 /*return*/];
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    result = void 0;
                    if (!(terminal === "admin")) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, service_1.updateFormDataByid)(val)];
                case 2:
                    result = _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    if (!(terminal === "patient")) return [3 /*break*/, 5];
                    return [4 /*yield*/, (0, service_1.saveFormDataByUser)(val)];
                case 4:
                    result = _a.sent();
                    setTimeout(function () {
                        loadFormData(); // 提交成功之后更新数据          
                    }, 500);
                    _a.label = 5;
                case 5:
                    // console.log("loadData result", result)
                    antd_2.message.success("更新成功");
                    setLoading(false);
                    return [3 /*break*/, 7];
                case 6:
                    error_3 = _a.sent();
                    console.log("loadFormData error", error_3);
                    setLoading(false);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    console.log("viewStatus", viewStatus);
    return (react_1.default.createElement(antd_2.Spin, { spinning: loading },
        react_1.default.createElement(antd_1.Form, __assign({}, formProps, { form: form, onAutoSubmit: handleSubmit }),
            react_1.default.createElement(SchemaField, { schema: schema }),
            !viewStatus ?
                react_1.default.createElement(antd_1.FormButtonGroup.FormItem, null,
                    react_1.default.createElement(antd_1.Submit, { block: true, size: "large" }, "\u63D0\u4EA4")) : null),
        react_1.default.createElement(antd_2.Divider, null),
        react_1.default.createElement(antd_2.Descriptions, { bordered: true, title: "", size: "small", column: 1 },
            react_1.default.createElement(antd_2.Descriptions.Item, { label: "\u8868\u5355\u586B\u5199 / \u4FEE\u6539\u65F6\u95F4", labelStyle: { width: 160 } },
                resultData.createTime,
                " / ",
                resultData.updateTime),
            react_1.default.createElement(antd_2.Descriptions.Item, { label: "\u586B\u5199\u4EBA / \u4FEE\u6539\u4EBA", labelStyle: { width: 160 } },
                resultData.realname,
                " / ",
                resultData.editRealname))));
};
exports.default = Preview;
