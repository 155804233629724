"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTabPane = exports.FormTab = void 0;
exports.FormTab = {
    'zh-CN': {
        title: '选项卡',
        addTabPane: '添加选项卡',
        settings: {
            'x-component-props': {
                animated: '启用动画过渡',
                centered: '标签居中',
                tab: '选项名称',
                type: {
                    title: '类型',
                    dataSource: [
                        { label: '线框', value: 'line' },
                        { label: '卡片', value: 'card' },
                    ],
                },
            },
        },
    },
    'en-US': {
        title: 'Tabs',
        addTabPane: 'Add Panel',
        settings: {
            'x-component-props': {
                animated: 'Enable Animated',
                centered: 'Label Centered',
                tab: 'Tab Title',
                type: {
                    title: 'Type',
                    dataSource: [
                        { label: 'Line', value: 'line' },
                        { label: 'Card', value: 'card' },
                    ],
                },
            },
        },
    },
    'ko-KR': {
        title: '탭',
        addTabPane: '패널 추가',
        settings: {
            'x-component-props': {
                animated: '애니메이션 활성화',
                centered: '레이블을 가운데로',
                tab: '텝 제목',
                type: {
                    title: '타입',
                    dataSource: [
                        { label: '라인', value: 'line' },
                        { label: '카드', value: 'card' },
                    ],
                },
            },
        },
    },
};
exports.FormTabPane = {
    'zh-CN': {
        title: '选项卡面板',
        settings: {
            'x-component-props': {
                tab: '面板标题',
            },
        },
    },
    'en-US': {
        title: 'Tab Panel',
        settings: {
            'x-component-props': {
                tab: 'Panel Title',
            },
        },
    },
    'ko-KR': {
        title: '탭 패널',
        settings: {
            'x-component-props': {
                tab: '패널 제목',
            },
        },
    },
};
