"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormCollapsePanel = exports.FormCollapse = void 0;
exports.FormCollapse = {
    'zh-CN': {
        title: '折叠面板',
        addCollapsePanel: '添加面板',
        settings: {
            'x-component-props': {
                accordion: '手风琴模式',
                collapsible: { title: '可折叠区域', dataSource: ['头部', '禁用'] },
                ghost: '幽灵模式',
                bordered: '是否有边框',
            },
        },
    },
    'en-US': {
        title: 'Collapse',
        addCollapsePanel: 'Add Panel',
        settings: {
            'x-component-props': {
                accordion: 'Accordion Mode',
                collapsible: {
                    title: 'Collapsible',
                    dataSource: ['Header', 'Disable'],
                },
                ghost: 'Ghost Mode',
                bordered: 'Bordered',
            },
        },
    },
    'ko-KR': {
        title: '드롭다운',
        addCollapsePanel: '패널 추가',
        settings: {
            'x-component-props': {
                accordion: 'Accordion 모드',
                collapsible: {
                    title: '드롭 여부',
                    dataSource: ['헤더', '비활성화'],
                },
                ghost: '고스트 모드',
                bordered: 'Bordered',
            },
        },
    },
};
exports.FormCollapsePanel = {
    'zh-CN': {
        title: '面板',
        settings: {
            'x-component-props': {
                collapsible: { title: '是否可折叠', dataSource: ['头部', '禁用'] },
                header: '标题',
                extra: '扩展内容',
            },
        },
    },
    'en-US': {
        title: 'Panel',
        settings: {
            'x-component-props': {
                collapsible: {
                    title: 'Collapsible',
                    dataSource: ['Header', 'Disable'],
                },
                header: 'Header Title',
                extra: 'Extra Content',
            },
        },
    },
    'ko-KR': {
        title: '패널',
        settings: {
            'x-component-props': {
                collapsible: {
                    title: '드롭 여부',
                    dataSource: ['해더', '비활성화'],
                },
                header: '헤더 제목',
                extra: '추가 내용',
            },
        },
    },
};
