"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cascader = void 0;
exports.Cascader = {
    type: 'object',
    properties: {
        allowClear: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        changeOnSelect: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        autoFocus: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        bordered: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
            'x-component-props': {
                defaultChecked: true,
            },
        },
        displayRender: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
            },
        },
        fieldNames: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
            'x-component-props': {
                include: ['EXPRESSION'],
            },
        },
        showSearch: {
            type: 'boolean',
            'x-decorator': 'FormItem',
            'x-component': 'Switch',
        },
        notFoundContent: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
            'x-component-props': {
                defaultValue: 'Not Found',
            },
        },
        placeholder: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
        },
        size: {
            type: 'string',
            enum: ['large', 'small', 'middle', null],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
                defaultValue: 'middle',
            },
        },
    },
};
