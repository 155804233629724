"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextArea = void 0;
exports.TextArea = {
    'zh-CN': {
        title: '多行输入',
        settings: {
            'x-component-props': {
                maxLength: '最大长度',
                autoSize: {
                    title: '自适应高度',
                    tooltip: '可设置为 true | false 或对象：{ minRows: 2, maxRows: 6 }',
                },
                showCount: '是否展示字数',
            },
        },
    },
    'en-US': {
        title: 'TextArea',
        settings: {
            'x-component-props': {
                maxLength: 'Max Length',
                autoSize: 'Auto Size',
                showCount: 'Show Count',
            },
        },
    },
    'ko-KR': {
        title: '텍스트 상자',
        settings: {
            'x-component-props': {
                maxLength: '최대 길이',
                autoSize: '자동 길이 변환',
                showCount: '개수 보기',
            },
        },
    },
};
