"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseQuery = void 0;
/**
 * 解析 QueryString [location.search部份]
 * @param url 待解析的url
 */
var parseQuery = function (url) {
    if (!url)
        return {};
    if (url.indexOf('?') === -1)
        return {};
    var querystring = url.substring(url.indexOf('?') + 1);
    var parseQuery = querystring.split('&');
    console.log('parseQuery', parseQuery);
    var parseRet = {};
    if (parseQuery.length) {
        parseQuery.forEach(function (item) {
            var cur = item.split('=');
            parseRet[cur[0]] = cur[1];
        });
    }
    return parseRet;
};
exports.parseQuery = parseQuery;
